import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import sanityClient from "../sanityClient";

const EventDetail = () => {
  const [event, setEvent] = useState(null);
  const { id: eventId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const data = await sanityClient.fetch(
          `*[_type == "event" && _id == $eventId][0]{
            title,
            date,
            description,
            "hostName": host->name,
            "themeName": theme->name,
            "themeDescription": theme->description,
            "moviesShown": moviesShown[]->title
          }`,
          { eventId }
        );
        setEvent(data);
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };

    fetchEvent();
  }, [eventId]);

  if (!event)
    return (
      <div className="content-container" style={{ textAlign: "center" }}>
        <p>Loading...</p>
      </div>
    );

  return (
    <div className="content-container">
      <button
        onClick={() => navigate(-1)}
        style={{
          marginBottom: "var(--spacing-md)",
          padding: "var(--spacing-sm) var(--spacing-md)",
          backgroundColor: "var(--color-accent)",
          border: "none",
          borderRadius: "var(--border-radius)",
          cursor: "pointer",
          color: "var(--color-bg)",
        }}
      >
        Go Back
      </button>
      <h1>{event.title}</h1>
      <p>Date: {new Date(event.date).toLocaleDateString()}</p>
      <p>{event.description}</p>

      {event.hostName && (
        <div style={{ marginTop: "var(--spacing-lg)" }}>
          <h2
            style={{
              fontSize: "var(--font-size-h3)",
              marginBottom: "var(--spacing-sm)",
            }}
          >
            Host
          </h2>
          <p>{event.hostName}</p>
        </div>
      )}

      {event.themeName && (
        <div style={{ marginTop: "var(--spacing-lg)" }}>
          <h2
            style={{
              fontSize: "var(--font-size-h3)",
              marginBottom: "var(--spacing-sm)",
            }}
          >
            Theme
          </h2>
          <p>
            {event.themeName} - {event.themeDescription}
          </p>
        </div>
      )}

      {event.moviesShown?.length > 0 && (
        <div style={{ marginTop: "var(--spacing-lg)" }}>
          <h2
            style={{
              fontSize: "var(--font-size-h3)",
              marginBottom: "var(--spacing-sm)",
            }}
          >
            Movies Shown
          </h2>
          <ul
            style={{ marginLeft: "var(--spacing-md)", listStyleType: "disc" }}
          >
            {event.moviesShown.map((movie, index) => (
              <li key={index}>{movie}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default EventDetail;
