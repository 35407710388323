import React, { useState, useEffect } from "react";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa"; // Importing star icons
import { useNavigate } from "react-router-dom";
import sanityClient from "../sanityClient";

const Log = () => {
  const [movies, setMovies] = useState([]);
  const [filteredMovies, setFilteredMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const moviesPerPage = 50; // Maximum movies per page
  const placeholderImage = "/img/generic_vhs.jpg";
  const navigate = useNavigate();

  const fetchTMDBData = async (title) => {
    try {
      const response = await fetch(
        `https://api.themoviedb.org/3/search/movie?api_key=8a08a585413d6340c209c3fca6b235d1&query=${encodeURIComponent(
          title
        )}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        const movieData = data.results[0];
        const detailsResponse = await fetch(
          `https://api.themoviedb.org/3/movie/${movieData.id}?api_key=8a08a585413d6340c209c3fca6b235d1&append_to_response=credits`
        );
        const detailsData = await detailsResponse.json();

        return {
          poster_path: movieData.poster_path
            ? `https://image.tmdb.org/t/p/w342${movieData.poster_path}`
            : placeholderImage,
          release_date: movieData.release_date || null,
          genres:
            detailsData.genres.map((genre) => genre.name).join(", ") || "N/A",
          director:
            detailsData.credits.crew.find((crew) => crew.job === "Director")
              ?.name || "Unknown",
          vote_average: detailsData.vote_average || "N/A",
        };
      }
    } catch (error) {
      console.error("Error fetching TMDB data:", error);
    }
    return {
      title,
      poster_path: placeholderImage,
      genres: "N/A",
      director: "Unknown",
      vote_average: "N/A",
    };
  };

  useEffect(() => {
    const fetchMovies = async () => {
      setLoading(true);
      try {
        const sanityMovies = await sanityClient.fetch(`*[_type == "movie"]{
          _id,
          title,
          watchDate
        }`);

        // Filter movies by ensuring the watchDate is in the past (strictly before today)
        const filteredSanityMovies = sanityMovies.filter((movie) => {
          const movieDate = new Date(movie.watchDate);
          const today = new Date();
          // Check if movieDate is strictly before today
          return movieDate < new Date(today.setHours(0, 0, 0, 0));
        });

        const moviesWithDetails = await Promise.all(
          filteredSanityMovies.map(async (movie) => {
            const tmdbData = await fetchTMDBData(movie.title);
            return { ...movie, ...tmdbData };
          })
        );

        // Sort movies by most recent first
        const sortedMovies = moviesWithDetails.sort(
          (a, b) => new Date(b.watchDate) - new Date(a.watchDate)
        );

        setMovies(sortedMovies);
        setFilteredMovies(sortedMovies);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching movies:", error);
        setLoading(false);
      }
    };

    fetchMovies();
  }, []);

  const formatDate = (dateString, formatType = "month") => {
    if (!dateString) return null;
    const date = new Date(dateString);
    if (formatType === "month") {
      return date.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      });
    } else if (formatType === "day") {
      return date.getDate().toString(); // Ensure it's a string
    }
  };

  const convertToStars = (voteAverage) => {
    if (voteAverage === "N/A") return "N/A";
    const starsOutOfFive = Math.round((voteAverage / 10) * 5 * 2) / 2; // Half-star precision
    const fullStars = Math.floor(starsOutOfFive);
    const halfStars = starsOutOfFive % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStars ? 1 : 0);

    return (
      <>
        {[...Array(fullStars)].map((_, i) => (
          <FaStar key={`full-${i}`} />
        ))}
        {halfStars && <FaStarHalfAlt />}
        {[...Array(emptyStars)].map((_, i) => (
          <FaRegStar key={`empty-${i}`} />
        ))}
      </>
    );
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = movies.filter(
      (movie) =>
        movie.title.toLowerCase().includes(query) ||
        movie.genres.toLowerCase().includes(query) ||
        formatDate(movie.watchDate, "month").toLowerCase().includes(query) ||
        formatDate(movie.watchDate, "day").includes(query) // Match day as a string
    );

    setFilteredMovies(filtered);
    setCurrentPage(1); // Reset to the first page after search
  };

  const groupMoviesByMonth = () => {
    return filteredMovies.reduce((acc, movie) => {
      const month = formatDate(movie.watchDate, "month");
      if (!acc[month]) acc[month] = [];
      acc[month].push(movie);
      acc[month].sort(
        (a, b) =>
          new Date(a.watchDate).getDate() - new Date(b.watchDate).getDate()
      );
      return acc;
    }, {});
  };

  const totalMovies = filteredMovies.length;
  const totalPages = Math.ceil(totalMovies / moviesPerPage);

  const getPaginatedMovies = () => {
    const allMovies = Object.values(groupMoviesByMonth()).flat();
    const startIndex = (currentPage - 1) * moviesPerPage;
    return allMovies.slice(startIndex, startIndex + moviesPerPage);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return (
      <div className="content-container" style={{ textAlign: "center" }}>
        <p>Loading movies...</p>
      </div>
    );
  }

  const paginatedMovies = getPaginatedMovies();
  const groupedPaginatedMovies = paginatedMovies.reduce((acc, movie) => {
    const month = formatDate(movie.watchDate, "month");
    if (!acc[month]) acc[month] = [];
    acc[month].push(movie);
    return acc;
  }, {});

  return (
    <div className="content-container">
      <h1 style={{ textAlign: "center", marginBottom: "var(--spacing-md)" }}>
        Watch Log
      </h1>

      {/* Search Bar */}
      <div style={{ marginBottom: "var(--spacing-lg)", textAlign: "center" }}>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search by title, genre, etc..."
          style={{
            padding: "var(--spacing-sm)",
            width: "50%",
            borderRadius: "var(--border-radius)",
            border: "1px solid var(--color-border)",
            backgroundColor: "var(--color-card)",
            color: "var(--color-text)",
          }}
        />
      </div>

      {Object.entries(groupedPaginatedMovies).map(([month, movies]) => (
        <div key={month} style={{ marginBottom: "var(--spacing-lg)" }}>
          <h2
            style={{
              color: "var(--color-text)",
              borderBottom: "1px solid var(--color-border)",
              paddingBottom: "var(--spacing-xs)",
            }}
          >
            {month}
          </h2>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "50px 300px 80px 150px 200px 100px",
              gap: "var(--spacing-sm)",
              paddingBottom: "var(--spacing-xs)",
              borderBottom: "1px solid var(--color-border)",
              fontWeight: "bold",
              color: "var(--color-text)",
              textAlign: "center",
            }}
          >
            <span>Day</span>
            <span>Film</span>
            <span>Released</span>
            <span>Director</span>
            <span>Genre</span>
            <span>Rating</span>
          </div>
          {movies.map((movie) => (
            <div
              key={movie._id}
              style={{
                display: "grid",
                gridTemplateColumns: "50px 300px 80px 150px 200px 100px",
                gap: "var(--spacing-sm)",
                padding: "var(--spacing-sm) 0",
                borderBottom: "1px solid var(--color-border)",
                alignItems: "center",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
                textAlign: "center",
              }}
              onClick={() => navigate(`/movie/${movie._id}`)}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "var(--color-hover)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "transparent")
              }
            >
              <div>{formatDate(movie.watchDate, "day")}</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "var(--spacing-sm)",
                  justifyContent: "left",
                }}
              >
                <img
                  src={movie.poster_path}
                  alt={movie.title}
                  style={{
                    width: "50px",
                    height: "75px",
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                />
                <span style={{ textAlign: "left" }}>{movie.title}</span>
              </div>
              <div>{movie.release_date?.split("-")[0] || "N/A"}</div>
              <div>{movie.director}</div>
              <div>{movie.genres}</div>
              <div>{convertToStars(movie.vote_average)}</div>
            </div>
          ))}
        </div>
      ))}

      {/* Pagination */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            style={{
              margin: "0 5px",
              padding: "5px 10px",
              backgroundColor:
                currentPage === index + 1
                  ? "var(--color-accent)"
                  : "var(--color-bg)",
              color:
                currentPage === index + 1
                  ? "var(--color-bg)"
                  : "var(--color-text)",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Log;
