import React, { useEffect } from "react";

export default function Stream() {
  useEffect(() => {
    // Optional: Dynamically set the video URL or perform any setup logic
    const videoElement = document.getElementById("streamVideo");
    if (videoElement) {
      videoElement.src = "//ok.ru/videoembed/6430870085130";
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "calc(100vh - 60px)", // Adjust based on your header's height
        backgroundColor: "#000", // Optional background for better contrast
      }}
    >
      <iframe
        id="streamVideo"
        src="//ok.ru/videoembed/6430870085130"
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        allowFullScreen
        title="Stream"
      ></iframe>
    </div>
  );
}
