import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sanityClient from "../sanityClient";

const Schedule = () => {
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const placeholderImage = "/img/generic_vhs.jpg";
  const navigate = useNavigate();

  const fetchTMDBData = async (title) => {
    try {
      const response = await fetch(
        `https://api.themoviedb.org/3/search/movie?api_key=8a08a585413d6340c209c3fca6b235d1&query=${encodeURIComponent(
          title
        )}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        const movieData = data.results[0];
        const movieDetailsResponse = await fetch(
          `https://api.themoviedb.org/3/movie/${movieData.id}?api_key=8a08a585413d6340c209c3fca6b235d1&append_to_response=credits`
        );
        const movieDetails = await movieDetailsResponse.json();

        return {
          poster_path: movieData.poster_path
            ? `https://image.tmdb.org/t/p/w342${movieData.poster_path}`
            : placeholderImage,
          release_date: movieData.release_date || "N/A",
          tagline: movieDetails.tagline || "",
          description: movieDetails.overview || "No description available.",
          director:
            movieDetails.credits?.crew?.find((crew) => crew.job === "Director")
              ?.name || "Unknown",
        };
      }
    } catch (error) {
      console.error("Error fetching TMDB data:", error);
    }
    return {
      poster_path: placeholderImage,
      release_date: "N/A",
      tagline: "",
      description: "No description available.",
      director: "Unknown",
    };
  };

  useEffect(() => {
    const fetchMovies = async () => {
      setLoading(true);
      try {
        const sanityMovies = await sanityClient.fetch(`*[_type == "movie"]{
          _id,
          title,
          watchDate
        }`);

        const now = new Date();
        now.setHours(0, 0, 0, 0); // Normalize today's date to 00:00:00

        const upcomingMovies = sanityMovies.filter((movie) => {
          if (!movie.watchDate) return false;
          const watchDate = new Date(movie.watchDate);
          return watchDate >= now; // Include today and future dates
        });

        const moviesWithDetails = await Promise.all(
          upcomingMovies.map(async (movie) => {
            const tmdbData = await fetchTMDBData(movie.title);
            return { ...movie, ...tmdbData };
          })
        );

        const sortedMovies = moviesWithDetails.sort(
          (a, b) => new Date(a.watchDate) - new Date(b.watchDate)
        );

        setMovies(sortedMovies);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching movies:", error);
        setLoading(false);
      }
    };

    fetchMovies();
  }, []);

  if (loading) {
    return (
      <div className="content-container" style={{ textAlign: "center" }}>
        <p>Loading schedule...</p>
      </div>
    );
  }

  return (
    <div className="content-container">
      <h1 style={{ textAlign: "center", marginBottom: "var(--spacing-md)" }}>
        Upcoming Movies
      </h1>
      {movies.length > 0 ? (
        <div
          style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {movies.map((movie) => (
            <div
              key={movie._id}
              style={{
                display: "grid",
                gridTemplateColumns: "200px 1fr 200px",
                alignItems: "center",
                gap: "1rem",
                backgroundColor: "var(--color-card)",
                padding: "var(--spacing-md)",
                borderRadius: "var(--border-radius)",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
              onClick={() => navigate(`/movie/${movie._id}`)}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "var(--color-hover)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "var(--color-card)";
              }}
            >
              {/* Poster */}
              <img
                src={movie.poster_path}
                alt={movie.title}
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "var(--border-radius)",
                }}
              />

              {/* Title, Director, Tagline, and Description */}
              <div>
                <h3
                  style={{
                    fontSize: "var(--font-size-h3)",
                    marginBottom: "var(--spacing-xs)",
                    color: "var(--color-text)",
                  }}
                >
                  {movie.title}{" "}
                  <span
                    style={{
                      fontWeight: "normal",
                      color: "var(--color-subtext)",
                    }}
                  >
                    ({movie.release_date.split("-")[0]})
                  </span>
                </h3>
                <p
                  style={{
                    fontSize: "var(--font-size-small)",
                    color: "var(--color-subtext)",
                    marginBottom: "var(--spacing-sm)",
                  }}
                >
                  Directed by <strong>{movie.director}</strong>
                </p>
                <p
                  style={{
                    fontSize: "var(--font-size-small)",
                    fontStyle: "italic",
                    color: "var(--color-subtext)",
                    marginBottom: "var(--spacing-sm)",
                  }}
                >
                  {movie.tagline}
                </p>
                <p
                  style={{
                    fontSize: "var(--font-size-small)",
                    color: "var(--color-text)",
                  }}
                >
                  {movie.description}
                </p>
              </div>

              {/* Watch Date */}
              <p
                style={{
                  fontSize: "var(--font-size-small)",
                  fontWeight: "bold",
                  color: "var(--color-text)",
                  textAlign: "center",
                }}
              >
                Watching on:{" "}
                {new Date(movie.watchDate).toLocaleDateString("en-US", {
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p style={{ color: "var(--color-subtext)", textAlign: "center" }}>
          No upcoming movies found.
        </p>
      )}
    </div>
  );
};

export default Schedule;
