import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../sanityClient";
import styled from "styled-components";

const MovieDetail = () => {
  const { id: movieId } = useParams();
  const [movie, setMovie] = useState(null);
  const [tmdbData, setTmdbData] = useState({
    cast: [],
    crew: [],
  });
  const [trailerUrl, setTrailerUrl] = useState("");

  useEffect(() => {
    const fetchMovieDetails = async () => {
      try {
        const sanityData = await sanityClient.fetch(
          `*[_type == "movie" && _id == $movieId][0]{
            title,
            watchDate,
            description,
            "theme": theme->name,
            "host": host->name
          }`,
          { movieId }
        );

        if (sanityData) {
          setMovie(sanityData);

          const tmdbResponse = await fetch(
            `https://api.themoviedb.org/3/search/movie?api_key=8a08a585413d6340c209c3fca6b235d1&query=${encodeURIComponent(
              sanityData.title
            )}`
          );
          const tmdbResult = await tmdbResponse.json();
          if (tmdbResult.results?.length) {
            const movieIdFromTMDB = tmdbResult.results[0].id;

            const movieDetailsResponse = await fetch(
              `https://api.themoviedb.org/3/movie/${movieIdFromTMDB}?api_key=8a08a585413d6340c209c3fca6b235d1&append_to_response=credits`
            );
            const movieDetails = await movieDetailsResponse.json();

            setTmdbData({
              poster_path: movieDetails.poster_path
                ? `https://image.tmdb.org/t/p/w342${movieDetails.poster_path}`
                : "/img/generic_vhs.jpg",
              release_date: movieDetails.release_date,
              overview: movieDetails.overview,
              director:
                movieDetails.credits?.crew?.find(
                  (crew) => crew.job === "Director"
                )?.name || "Unknown",
              tagline: movieDetails.tagline || "",
              vote_average: movieDetails.vote_average || "N/A",
              backdrop_path: movieDetails.backdrop_path
                ? `https://image.tmdb.org/t/p/original${movieDetails.backdrop_path}`
                : null,
              cast: movieDetails.credits?.cast || [],
              crew: movieDetails.credits?.crew || [],
            });

            // Generate Trailer URL
            await generateTrailerUrl(
              `${sanityData.title} ${
                movieDetails.release_date?.split("-")[0]
              } trailer`
            );
          }
        }
      } catch (error) {
        console.error("Error fetching movie details:", error);
      }
    };

    const generateTrailerUrl = async (query) => {
      try {
        const searchUrl = `https://www.youtube.com/results?search_query=${encodeURIComponent(
          query
        )}`;
        const response = await fetch(searchUrl);
        const html = await response.text();
        const videoIdMatch = html.match(/"videoId":"([a-zA-Z0-9_-]{11})"/);

        if (videoIdMatch && videoIdMatch[1]) {
          const videoId = videoIdMatch[1];
          setTrailerUrl(`https://www.youtube.com/embed/${videoId}`);
        }
      } catch (error) {
        console.error("Error fetching YouTube trailer:", error);
      }
    };

    fetchMovieDetails();
  }, [movieId]);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const constructIMDbLink = (name) =>
    `https://www.imdb.com/find?q=${encodeURIComponent(name)}&s=nm`;

  const convertToStars = (voteAverage) => {
    if (voteAverage === "N/A") return "N/A";
    const starsOutOfFive = Math.round((voteAverage / 10) * 5);
    return "★".repeat(starsOutOfFive) + "☆".repeat(5 - starsOutOfFive);
  };

  if (!movie) return <p>Loading...</p>;

  return (
    <div className="content-container">
      {/* Backdrop Section */}
      {tmdbData.backdrop_path && (
        <Backdrop style={{ backgroundImage: `url(${tmdbData.backdrop_path})` }}>
          <BackdropFade />
        </Backdrop>
      )}

      <Layout>
        {/* Sticky Poster Column */}
        <PosterColumn>
          <Poster src={tmdbData.poster_path} alt={movie.title} />
        </PosterColumn>

        {/* Content Column */}
        <ContentColumn>
          <DetailLayout>
            {/* Middle Column: Title, Tagline, Description */}
            <MiddleColumn>
              <Title>
                {movie.title}{" "}
                {tmdbData.release_date && (
                  <span>({new Date(tmdbData.release_date).getFullYear()})</span>
                )}
              </Title>
              <DirectedBy>
                Directed by <strong>{tmdbData.director || "Unknown"}</strong>
              </DirectedBy>
              {tmdbData.tagline && <Tagline>"{tmdbData.tagline}"</Tagline>}
              <Description>
                {movie.description ||
                  tmdbData.overview ||
                  "No description available."}
              </Description>

              {/* YouTube Trailer */}
              {trailerUrl && (
                <Trailer>
                  <iframe
                    width="560"
                    height="315"
                    src={trailerUrl}
                    title="YouTube trailer"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Trailer>
              )}
            </MiddleColumn>

            {/* Right Column: Metadata */}
            <RightColumn>
              <Metadata>
                <MetaItem>
                  <strong>Last Watched:</strong> {formatDate(movie.watchDate)}
                </MetaItem>
                <MetaItem>
                  <strong>BMN Rating:</strong> ⭐⭐⭐⭐⭐
                </MetaItem>
                <MetaItem>
                  <strong>Vote Average:</strong>{" "}
                  {convertToStars(tmdbData.vote_average)}
                </MetaItem>
                <MetaItem>
                  <strong>Theme:</strong> {movie.theme || "N/A"}
                </MetaItem>
                <MetaItem>
                  <strong>Host:</strong> {movie.host || "N/A"}
                </MetaItem>
              </Metadata>
            </RightColumn>
          </DetailLayout>

          {/* Cast and Crew Section */}
          <CastCrewSection>
            <h2>Cast and Crew</h2>
            <CastCrewGrid>
              <div>
                <h3>Cast</h3>
                <CastList>
                  {tmdbData.cast.slice(0, 25).map((actor) => (
                    <li key={actor.id}>
                      <a
                        href={constructIMDbLink(actor.name)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong>{actor.name}</strong>
                      </a>{" "}
                      as {actor.character}
                    </li>
                  ))}
                </CastList>
              </div>
              <div>
                <h3>Crew</h3>
                <CrewList>
                  {tmdbData.crew.slice(0, 25).map((crewMember) => (
                    <li key={crewMember.id}>
                      <a
                        href={constructIMDbLink(crewMember.name)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong>{crewMember.name}</strong>
                      </a>{" "}
                      - {crewMember.job}
                    </li>
                  ))}
                  {tmdbData.crew.length > 25 && <p>And more...</p>}
                </CrewList>
              </div>
            </CastCrewGrid>
          </CastCrewSection>
        </ContentColumn>
      </Layout>
    </div>
  );
};

// Styled Components
const Backdrop = styled.div`
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  position: relative;
`;

const BackdropFade = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to bottom, transparent, var(--color-bg));
`;

const Layout = styled.div`
  display: flex;
  gap: var(--spacing-lg);
  padding: var(--spacing-lg) 0;
`;

const PosterColumn = styled.div`
  flex: 0 0 300px;
  position: sticky;
  top: var(--spacing-lg);
  align-self: flex-start;
`;

const Poster = styled.img`
  width: 100%;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
`;

const ContentColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const DetailLayout = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: var(--spacing-lg);
`;

const MiddleColumn = styled.div``;

const Title = styled.h1`
  font-size: var(--font-size-h1);
  margin-bottom: var(--spacing-sm);
`;

const DirectedBy = styled.h2`
  font-size: var(--font-size-body);
  color: var(--color-subtext);
  margin-bottom: var(--spacing-md);
`;

const Tagline = styled.h2`
  font-size: var(--font-size-body);
  color: var(--color-subtext);
  font-style: italic;
  margin-bottom: var(--spacing-md);
`;

const Description = styled.p`
  font-size: var(--font-size-body);
  line-height: 1.6;
`;

const RightColumn = styled.div`
  background-color: var(--color-card);
  padding: var(--spacing-md);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Metadata = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;

const MetaItem = styled.div`
  font-size: var(--font-size-body);
  color: var(--color-text);
`;

const CastCrewSection = styled.div`
  margin-top: var(--spacing-lg);
`;

const CastCrewGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-lg);
`;

const CastList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const CrewList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const Trailer = styled.div`
  margin-top: var(--spacing-lg);
  iframe {
    width: 100%;
    max-width: 560px;
    height: 315px;
    border-radius: var(--border-radius);
  }
`;

export default MovieDetail;
