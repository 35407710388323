import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Header = () => {
  return (
    <HeaderContainer>
      <ContentWrapper>
        <Logo>
          <Link to="/">BMN</Link>
        </Logo>
        <Nav>
          <NavItem to="/">TUNE IN</NavItem>
          <NavItem to="/schedule">SCHEDULE</NavItem>
          <NavItem to="/log">WATCH LOG</NavItem>
          <NavItem to="/blog">BLOG</NavItem>
          <NavItem to="/blog">REVIEWS</NavItem>
          <NavItem to="/events">EVENTS</NavItem>
        </Nav>
      </ContentWrapper>
    </HeaderContainer>
  );
};

export default Header;

// Styled Components
const HeaderContainer = styled.header`
  background-color: var(--color-card);
  border-bottom: 1px solid var(--color-border);
  height: 60px; /* Consistent header height */
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Limit the width to keep it centered */
  padding: 0 var(--spacing-lg); /* Add padding on the sides */
`;

const Logo = styled.div`
  font-size: var(--font-size-h2);
  font-weight: 600;

  a {
    color: var(--color-accent);
    text-decoration: none;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: var(--spacing-md);
`;

const NavItem = styled(Link)`
  color: var(--color-subtext); /* Letterboxd-like gray */
  text-decoration: none; /* No underline */
  font-size: var(--font-size-body);
  font-weight: 600;
  transition: color 0.3s ease-in-out; /* Smooth transition for color change */

  &:hover {
    color: var(--color-text); /* Transition to white */
  }
`;
