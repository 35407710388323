import { createClient } from "@sanity/client";

const client = createClient({
  projectId: "alisl6nk", // Replace with your Sanity project ID
  dataset: "production", // Replace with your dataset name
  apiVersion: "2023-01-01", // Use the current date for the latest version
  useCdn: true, // Enable the CDN for faster, cached responses
});

export default client;
