import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../sanityClient";
import styled from "styled-components";

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const data = await sanityClient.fetch(`
          *[_type == "blogPost"] | order(_createdAt desc) {
            _id,
            title,
            "relatedMovies": related.relatedMovies[]->title,
            mainImage {
              asset -> {
                url
              },
              alt
            },
            content[0] {
              children[0] {
                text
              }
            }
          }
        `);
        setPosts(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      }
    };

    fetchPosts();
  }, []);

  if (loading)
    return (
      <div className="content-container" style={{ textAlign: "center" }}>
        <p>Loading blog posts...</p>
      </div>
    );

  const featuredPost = posts[0];
  const otherPosts = posts.slice(1);

  return (
    <div className="content-container">
      {/* Featured Post */}
      {featuredPost && (
        <FeaturedPost>
          <img
            src={featuredPost.mainImage?.asset?.url || "/img/placeholder.jpg"}
            alt={featuredPost.mainImage?.alt || featuredPost.title}
          />
          <FeaturedContent>
            <h2>{featuredPost.title}</h2>
            <p className="teaser">
              {featuredPost.content?.children?.text ||
                "No description available."}
            </p>
            <Link to={`/blog/${featuredPost._id}`}>Read More</Link>
          </FeaturedContent>
        </FeaturedPost>
      )}

      {/* Other Posts */}
      <PostGrid>
        {otherPosts.map((post) => (
          <PostCard key={post._id}>
            <Link to={`/blog/${post._id}`}>
              <img
                src={post.mainImage?.asset?.url || "/img/placeholder.jpg"}
                alt={post.mainImage?.alt || post.title}
              />
              <div>
                <h3>{post.title}</h3>
                <p className="teaser">
                  {post.content?.children?.text || "No description available."}
                </p>
                {post.relatedMovies?.length > 0 && (
                  <p>Related Movies: {post.relatedMovies.join(", ")}</p>
                )}
              </div>
            </Link>
          </PostCard>
        ))}
      </PostGrid>
    </div>
  );
};

// Styled Components
const FeaturedPost = styled.div`
  display: flex;
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);

  img {
    flex: 1;
    max-height: 300px;
    object-fit: cover;
    border-radius: var(--border-radius);
  }

  h2 {
    font-size: var(--font-size-h1);
    margin-bottom: var(--spacing-sm);
  }

  .teaser {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: var(--spacing-md);
    color: var(--color-subtext);
    position: relative;
    line-height: 1.5em;
    max-height: 4.5em; /* 3 lines x 1.5em line-height */
  }

  .teaser::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 1.5em; /* Matches line-height */
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      var(--color-bg) 100%
    );
  }

  a {
    color: var(--color-accent);
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const FeaturedContent = styled.div`
  flex: 1;
`;

const PostGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-md);
`;

const PostCard = styled.div`
  background-color: var(--color-card);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  img {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }

  h3 {
    font-size: var(--font-size-h3);
    margin: var(--spacing-md);
    color: var(--color-text);
  }

  .teaser {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 var(--spacing-md) var(--spacing-sm);
    color: var(--color-subtext);
    position: relative;
    line-height: 1.5em;
    max-height: 4.5em; /* 3 lines x 1.5em line-height */
  }

  .teaser::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 1.5em; /* Matches line-height */
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      var(--color-bg) 100%
    );
  }

  p {
    font-size: var(--font-size-small);
    color: var(--color-subtext);
    margin: 0 var(--spacing-md) var(--spacing-sm);
  }
`;

export default BlogList;
