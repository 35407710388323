import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../sanityClient";
import { useForm, ValidationError } from "@formspree/react";

const EventList = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const data = await sanityClient.fetch(`
          *[_type == "event"]{
            _id,
            title,
            date,
            description
          }
        `);
        setEvents(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const ContactForm = () => {
    const [state, handleSubmit] = useForm("mrbbwvvg"); // Replace with your Formspree form ID
    if (state.succeeded) {
      return (
        <div className="card" style={{ textAlign: "center", padding: "2rem" }}>
          <h2>Thank you!</h2>
          <p>We’ve received your inquiry and will get back to you soon.</p>
        </div>
      );
    }

    return (
      <form
        onSubmit={handleSubmit}
        className="card"
        style={{
          marginTop: "var(--spacing-lg)",
          maxWidth: "800px",
          margin: "var(--spacing-lg) auto",
        }}
      >
        <h2
          style={{
            marginBottom: "var(--spacing-md)",
            textAlign: "center",
          }}
        >
          Host a Pop-Up Bad Movie Night in Buffalo!
        </h2>
        <p
          style={{
            marginBottom: "var(--spacing-lg)",
            textAlign: "center",
            color: "var(--color-subtext)",
          }}
        >
          We’re looking for movie theaters, bars, and other venues in Western
          New York to host our **Bad Movie Night** pop-ups. These fun,
          interactive events bring:
        </p>
        <ul
          style={{
            marginBottom: "var(--spacing-lg)",
            paddingLeft: "var(--spacing-md)",
            listStyleType: "disc",
          }}
        >
          <li>Drinking games</li>
          <li>Trivia challenges</li>
          <li>Crowd participation</li>
          <li>An unforgettable night for your patrons</li>
        </ul>
        <p
          style={{
            textAlign: "center",
            marginBottom: "var(--spacing-lg)",
          }}
        >
          Bring in a crowd, boost engagement, and make your venue the talk of
          the town! Fill out the form below to inquire about hosting.
        </p>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "var(--spacing-lg)",
            marginBottom: "var(--spacing-lg)",
          }}
        >
          <div>
            <label
              htmlFor="name"
              style={{
                display: "block",
                marginBottom: "var(--spacing-xs)",
              }}
            >
              Your Name
            </label>
            <input
              id="name"
              type="text"
              name="name"
              required
              placeholder="Enter your name"
              className="form-input"
            />
            <ValidationError prefix="Name" field="name" errors={state.errors} />
          </div>

          <div>
            <label
              htmlFor="email"
              style={{
                display: "block",
                marginBottom: "var(--spacing-xs)",
              }}
            >
              Email Address
            </label>
            <input
              id="email"
              type="email"
              name="email"
              required
              placeholder="Enter your email"
              className="form-input"
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
          </div>

          <div>
            <label
              htmlFor="location"
              style={{
                display: "block",
                marginBottom: "var(--spacing-xs)",
              }}
            >
              Venue Name & Location
            </label>
            <input
              id="location"
              type="text"
              name="location"
              required
              placeholder="Enter venue name and location"
              className="form-input"
            />
            <ValidationError
              prefix="Location"
              field="location"
              errors={state.errors}
            />
          </div>

          <div>
            <label
              htmlFor="phone"
              style={{
                display: "block",
                marginBottom: "var(--spacing-xs)",
              }}
            >
              Phone Number (Optional)
            </label>
            <input
              id="phone"
              type="tel"
              name="phone"
              placeholder="Enter your phone number"
              className="form-input"
            />
            <ValidationError
              prefix="Phone"
              field="phone"
              errors={state.errors}
            />
          </div>
        </div>

        <div style={{ marginBottom: "var(--spacing-lg)" }}>
          <label
            htmlFor="message"
            style={{
              display: "block",
              marginBottom: "var(--spacing-xs)",
            }}
          >
            Additional Details
          </label>
          <textarea
            id="message"
            name="message"
            placeholder="Tell us more about your venue and goals"
            rows="4"
            className="form-input"
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </div>

        <button
          type="submit"
          disabled={state.submitting}
          className="button"
          style={{
            display: "block",
            width: "100%",
            marginTop: "var(--spacing-md)",
          }}
        >
          Submit Inquiry
        </button>
      </form>
    );
  };

  if (loading)
    return (
      <div className="content-container" style={{ textAlign: "center" }}>
        <p>Loading events...</p>
      </div>
    );

  return (
    <div className="content-container">
      <h1 style={{ textAlign: "center", marginBottom: "var(--spacing-lg)" }}>
        Bad Movie Night Events
      </h1>
      {events.length > 0 ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gap: "var(--spacing-md)",
          }}
        >
          {events.map((event) => (
            <div key={event._id} className="card">
              <Link
                to={`/events/${event._id}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <h2
                  style={{
                    fontSize: "var(--font-size-h3)",
                    marginBottom: "var(--spacing-sm)",
                  }}
                >
                  {event.title}
                </h2>
                <p
                  style={{
                    marginBottom: "var(--spacing-xs)",
                    color: "var(--color-subtext)",
                  }}
                >
                  {new Date(event.date).toLocaleDateString()}
                </p>
                <p style={{ fontSize: "var(--font-size-small)" }}>
                  {event.description.slice(0, 100)}...
                </p>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: "var(--spacing-md)" }}>
          <p>No live events are currently scheduled.</p>
          <p>
            Want to join the fun? Check out our{" "}
            <Link to="/schedule" style={{ color: "var(--color-accent)" }}>
              weekly live stream
            </Link>{" "}
            on{" "}
            <a
              href="https://badmovienight.tv"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "var(--color-accent)",
                textDecoration: "underline",
              }}
            >
              badmovienight.tv
            </a>{" "}
            every Friday at 9 PM EST!
          </p>
        </div>
      )}
      <ContactForm />
    </div>
  );
};

export default EventList;
