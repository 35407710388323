import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Stream from "./pages/Stream";
import Log from "./pages/Log";
import Schedule from "./pages/Schedule";
import MovieDetail from "./components/MovieDetail";
import BlogList from "./pages/BlogList";
import BlogPost from "./pages/BlogPost";
import EventList from "./pages/EventList";
import EventDetail from "./pages/EventDetail";
import "./App.css";

const App = () => {
  return (
    <div id="root">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Stream />} />
          <Route path="/log" element={<Log />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/movie/:id" element={<MovieDetail />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/events" element={<EventList />} />
          <Route path="/events/:id" element={<EventDetail />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
