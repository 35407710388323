import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import sanityClient from "../sanityClient";

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const { id: postId } = useParams();
  const navigate = useNavigate();

  // Replace these values with your Sanity project's details
  const sanityConfig = {
    projectId: "yourProjectId", // Replace with your projectId
    dataset: "yourDataset", // Replace with your dataset name
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const data = await sanityClient.fetch(
          `*[_type == "blogPost" && _id == $postId][0]{
            title,
            mainImage {
              asset -> { url },
              alt
            },
            content,
            related {
              relatedMovies[] -> { title },
              relatedHosts[] -> { name },
              relatedThemes[] -> { name },
              relatedEvents[] -> { title },
              relatedReviews[] -> { content }
            }
          }`,
          { postId }
        );
        setPost(data);
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };

    fetchPost();
  }, [postId]);

  if (!post) {
    return (
      <div className="content-container" style={{ textAlign: "center" }}>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="content-container">
      {/* Title and Main Image */}
      <h1
        style={{
          fontSize: "var(--font-size-h1)",
          marginBottom: "var(--spacing-md)",
        }}
      >
        {post.title}
      </h1>
      {post.mainImage?.asset?.url && (
        <img
          src={post.mainImage.asset.url}
          alt={post.mainImage.alt || "Blog Post Image"}
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "var(--border-radius)",
            margin: "var(--spacing-md) 0",
          }}
        />
      )}

      {/* Content */}
      <div
        style={{
          margin: "var(--spacing-lg) 0",
          lineHeight: "1.8",
          color: "var(--color-text)",
        }}
      >
        <BlockContent blocks={post.content} {...sanityConfig} />
      </div>

      {/* Related Content Sections */}
      {post.related?.relatedMovies?.length > 0 && (
        <RelatedSection
          title="Related Movies"
          items={post.related.relatedMovies}
        />
      )}
      {post.related?.relatedHosts?.length > 0 && (
        <RelatedSection
          title="Related Hosts"
          items={post.related.relatedHosts}
        />
      )}
      {post.related?.relatedThemes?.length > 0 && (
        <RelatedSection
          title="Related Themes"
          items={post.related.relatedThemes}
        />
      )}
      {post.related?.relatedEvents?.length > 0 && (
        <RelatedSection
          title="Related Events"
          items={post.related.relatedEvents}
        />
      )}
      {post.related?.relatedReviews?.length > 0 && (
        <RelatedSection
          title="Related Reviews"
          items={post.related.relatedReviews.map((review) => review.content)}
        />
      )}

      {/* Go Back Button */}
      <button
        className="back-button"
        onClick={() => navigate(-1)}
        style={{
          marginTop: "var(--spacing-lg)",
          padding: "var(--spacing-sm) var(--spacing-md)",
          backgroundColor: "var(--color-accent)",
          border: "none",
          borderRadius: "var(--border-radius)",
          cursor: "pointer",
          color: "var(--color-bg)",
          display: "block",
          width: "fit-content",
        }}
      >
        Go Back
      </button>
    </div>
  );
};

const RelatedSection = ({ title, items }) => (
  <div style={{ marginTop: "var(--spacing-lg)" }}>
    <h2
      style={{
        fontSize: "var(--font-size-h2)",
        marginBottom: "var(--spacing-sm)",
      }}
    >
      {title}
    </h2>
    <ul
      style={{
        marginLeft: "var(--spacing-md)",
        listStyleType: "disc",
        color: "var(--color-subtext)",
      }}
    >
      {items.map((item, index) => (
        <li key={index}>{item.title || item.name || item}</li>
      ))}
    </ul>
  </div>
);

export default BlogPost;
